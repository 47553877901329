import initOnceRequest from 'request/manage-stylist/deposit-init-once'
import studiosRequest from 'request/search/studios'

export default function DepositInitOncePanel() {
  const t = useI18n()
  const { state, mutate } = useStatic({
    visible: false,
    stylist: null
  })

  h.openDepositInitOnce = (userId, refreshDeposit) => {
    state.visible = true
    state.userId = userId
    state.refreshDeposit = refreshDeposit
    mutate()
  }

  const closePanel = () => {
    state.visible = false
    mutate()
  }

  const renderBody = () => {
    if (!state.visible) return
    return <Body state={ state } closePanel={ closePanel } t={ t } />
  }

  return (
    <C.Modal
      visible={ state.visible }
      close={ closePanel }
      noPadding
      confirmOnClose
    >
      <C.ModalHeader title={ t('panel:depositInitOnce.title') }>
        {renderBody()}
      </C.ModalHeader>
    </C.Modal>
  )
}

const Body = ({ state, t, closePanel }) => {
  const form = useForm({
    amount: { value: '', rule: 'required' },
    studio: { value: '', rule: 'required' }
  })

  const submit = async() => {
    if (!form.studio.value) return
    const studioId = form.studio.value.id
    await initOnceRequest({
      userId: state.userId,
      amount: form.amount.value,
      studioId
    })
    closePanel()
    await state.refreshDeposit()
    $alert(t('common:createSuccess'))
  }

  const studioOptions = studio => {
    const { id, name } = studio
    return {
      ...studio,
      key: id,
      value: name
    }
  }

  const studioLoadMore = ({ keyword, offset, limit }) => studiosRequest({ keyword, offset, limit, authorized: true })

  return <>
    <div className='flex-1 p-5 flex flex-col'>
      <div>
        <C.InputForm
          variant='standard'
          label={ t('panel:depositInitOnce.amount') }
          form={ form }
          field='amount'
          fullWidth
          number
        />
      </div>
      <div>
        <C.AsyncSelectForm
          label={ t('panel:depositInitOnce.studio') }
          field='studio'
          form={ form }
          maxCheckedCount={ 1 }
          optionFormat={ studioOptions }
          optionRender={ option => (
            <div className=''>
              <div className='line-clamp-1'>{option.value}</div>
              <div className='text-sm text-gray-400'>{option.address}</div>
            </div>
          ) }
          loadMore={ studioLoadMore }
        />
      </div>
      <div className='flex-1 pt-10'>
        <C.Button check={ form } onClick={ submit } className='w-full'>
          {t('common:submit')}
        </C.Button>
      </div>
    </div>
  </>
}

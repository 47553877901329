import DepositDetail from './panel/detail'
import DepositInitOnce from './panel/init-once'
import myDepositsRequest from 'request/my/deposits'
import adminDepositRequest from 'request/admin/deposits'

export default function DepositRecords({ isAdmin, userId }) {
  const t = useI18n()
  const { state, mutate } = useStatic({
    records: [],
    fetching: true
  })

  const loadDeposits = async() => {
    state.fetching = false
    state.records = await requestResolver(isAdmin, userId)
    formatOfficialDeposit(state.records)
    mutate()
  }

  const formatOfficialDeposit = deposits => {
    console.log(deposits)
    const officailDeposit = deposits.find(deposit => deposit.stylist?.user?.id === '1')
    if (!officailDeposit) return
    officailDeposit.stylist.user.name = t('common:stylemap')
  }

  useEffect(() => {
    loadDeposits()
  }, [])

  const requestResolver = async(isAdmin, userId) => {
    if (isAdmin) return adminDepositRequest(userId)
    return myDepositsRequest()
  }

  const detailClick = (stylistName, depositId) => {
    h.openDepositDetail({ stylistName, depositId, isAdmin })
  }

  const renderLoading = () => {
    if (state.fetching) return <div className='py-5'><C.Loading /></div>
    if (state.records.length) return
    return <div className='py-5 text-center text-gray-600'>{t('page:my.deposits.noRecords')}</div>
  }

  const renderButton = () => {
    if (!isAdmin) return
    return (
      <div className='flex p-4'>
        <C.Button onClick={ () => { h.openDepositInitOnce(userId, loadDeposits) } } className='w-full'>
          {t('panel:depositInitOnce.title')}
        </C.Button>
      </div>
    )
  }

  return (
    <>
      <Row
        className='font-bold text-sm px-5'
        values={ [
          t('page:my.deposits.stylist'),
          t('page:my.deposits.amount'),
          t('page:my.deposits.detail')
        ] }
      />
      <Hr />
      <div className='flex-1 overflow-scroll text-sm pb-5 px-5'>
        {state.records.map(({ id, amount, coupon, stylist }) => (
          <Row
            key={ id }
            className='font-medium'
            values={ [
              stylist.user.name,
              (amount + coupon).currency(),
              <div key='detail' onClick={ () => detailClick(stylist.user.name, id) } className='border-gray-500 border-[0.125rem] rounded-[4px] px-[5px] py-[1px] cursor-pointer text-gray-600'>
                <Icon.Detail className='w-4 h-4 text-gray-500' />
              </div>
            ] }
          />
        ))}
        {renderLoading()}
      </div>
      <C.Blur />
      {renderButton()}
      <DepositDetail />
      <DepositInitOnce />
    </>
  )
}

const Row = ({ values, className }) => (
  <div className={ cn('flex space-x-3 items-center py-5', className) }>
    <div className='flex-1 truncate'>{values[0]}</div>
    <div className='min-w-[15%] text-right shrink-0'>{values[1]}</div>
    <div className='min-w-[15%] flex justify-end text-right shrink-0'>{values[2]}</div>
  </div>
)

const Hr = () => <div className='border-b'></div>
